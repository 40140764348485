import { useCallback, useState } from "react";
import { capitalize } from "./capitalize";
import { useFeedError } from "./feedHooks";
import { FETCH_ERROR } from "../consts";

export type FormErrorState = null | string;

export const useFormError = (initialState: FormErrorState = null) =>
  useState<FormErrorState>(initialState);

export type FieldsErrorState = null | Record<string, string[]>;

export type SetFieldsErrorsAction = React.Dispatch<
  React.SetStateAction<FieldsErrorState>
> | null;

export const useFieldsError = (initialState: FieldsErrorState = null) =>
  useState<FieldsErrorState>(initialState);

export const fieldError = (
  field: string,
  formErrors?: FieldsErrorState
): [boolean, string | undefined] => {
  const errors = formErrors && formErrors[field];

  if (!errors) {
    return [false, undefined];
  }

  const errorMessage = errors.join(", ");

  return [
    !!errorMessage,
    errorMessage ? capitalize(errorMessage) : errorMessage,
  ];
};

export const useSubmitFormErrorHander = (defaultErrorMessage: string) => {
  const [fieldsErrors, setFieldsErrors] = useFieldsError();
  const [formError, setFormError] = useFormError();
  const feedError = useFeedError();

  const catchFormError = useCallback(
    (error) => {
      if (typeof error === "string") {
        setFormError(error);
      }

      const status = error?.data?.status ?? error?.status;
      const message = error?.data?.message ?? error?.message;

      if (status === FETCH_ERROR) {
        return feedError(defaultErrorMessage);
      }

      if (message) {
        return typeof message === "string"
          ? setFormError(message)
          : setFieldsErrors(message);
      }

      setFormError(defaultErrorMessage);
    },
    [defaultErrorMessage, feedError, setFieldsErrors, setFormError]
  );

  return { catchFormError, formError, fieldsErrors, setFieldsErrors };
};

export const formatFieldArrayIds = <Item extends Record<string, unknown>>(
  fields: Array<Item>
) =>
  fields.map((field) => {
    if ("dbId" in field) {
      const { dbId, ...rest } = field;
      return { id: dbId, ...rest };
    }

    return field;
  });

const deleteFieldError = (
  field: string,
  fieldsErrors: FieldsErrorState,
  setFieldsErrors: SetFieldsErrorsAction
) => {
  const newState = { ...fieldsErrors };
  delete newState[field];
  return setFieldsErrors ? setFieldsErrors(newState) : null;
};

export const onFieldChange = (
  fieldName: string,
  fieldsErrors: FieldsErrorState,
  setFieldsErrors: SetFieldsErrorsAction
) => deleteFieldError(fieldName, fieldsErrors, setFieldsErrors);
