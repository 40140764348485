import { MatchOption } from "../../types";
import { Product, ProductColors } from "../products/types";
import { Supplier } from "../suppliers/types";
import { GridSortModel } from "@mui/x-data-grid";
import { PurchaseOrderWithStatistics } from "../purchaseOrders/types";
import { MainOrder } from "../mainOrders/types";
import { DepartmentInquiry, Entity, SupplierProductCode } from "../../entities";

export const itemSchoolStatuses = [
  "new",
  "approvedByHead",
  "needReviewByHead",
  "approvedByPrincipal",
  "cancelled",
] as const;
export type ItemSchoolStatus = typeof itemSchoolStatuses[number];

export const itemInquiryStatuses = [
  "new",
  "matched",
  "submitted",
  "confirmed",
  "delivered",
  "discontinued",
  "canceled",
] as const;

export type ItemInquiryStatus = typeof itemInquiryStatuses[number];

export type ItemInquiryStatistics = Record<ItemInquiryStatus, number>;

export const itemOrderStatuses = [
  "new",
  "placed",
  "confirmed",
  "readyToDispatch",
  "shipped",
  "clearance",
  "warehouse",
  "delivered",
  "discontinued",
] as const;

export type ItemOrderStatus = typeof itemOrderStatuses[number];

export type ItemOrderStatistics = Record<ItemOrderStatus, number>;

export type Item = Entity & {
  internalLineNumber: number;
  customerLineNumber: number | null;

  name: string;
  quantity: number;
  supplierProductCode?: string;

  departmentInquiry: DepartmentInquiry;
  supplier?: Supplier;

  schoolStatus: ItemSchoolStatus;
  inquiryStatus: ItemInquiryStatus;
  orderStatus?: ItemOrderStatus;

  productQuantity?: number;
  productColor?: ProductColors;
  productRetailPrice?: number | null;

  product?: Omit<Product, "supplierProductCode"> & {
    supplierProductCode: SupplierProductCode;
  };
  match: MatchOption | null;

  purchaseOrder: Omit<
    PurchaseOrderWithStatistics,
    "inquiry" | "uploadedBy"
  > | null;
  mainOrder: Omit<MainOrder, "inquiry" | "supplier" | "uploadedBy"> | null;

  // autoMatchPassed: boolean; // TODO_Match add after match functionality added
  // importFile?: InquiryFIle // TODO_Import add after importing added
};

export type ItemDto = Omit<
  Item,
  // Used in Add/Edit request as ID's
  | "departmentInquiry"
  | "supplier"
  // Not used in Add/Edit request
  | "product"
  | "inquiryStatus"
  | "orderStatus"
  | "productQuantity"
  | "productColor"
  | "createdAt"
  | "updatedAt"
  // | "autoMatchPassed" // TODO_Match add after match functionality added
  // | "importFile" // TODO_Import add after importing added
> & {
  departmentInquiryId: number;
  supplierId: number | null;
};

export type ItemFlattened = Omit<Item, "supplier"> & {
  supplier?: string;
  // product?: string | null;
};

export type ItemsRowsRequest = {
  page: number;
  pageSize: number;
  sortModel?: GridSortModel;
  search?: string;
  departmentInquiryId?: number;
  inquiryStatusFilter?: string[];
  orderStatusFilter?: string[];
  purchaseOrderId?: number;
  mainOrderId?: number;
};

export type ItemRowsResponse = {
  data: Item[];
  rowCount: number;
  parentName: string;
  inquiryName: string;
  inquiryStatistics: ItemInquiryStatistics;
  orderStatistics: ItemOrderStatistics;
};

export type GetNextNewItemsRequestDto = {
  id: number;
  isInInquiry: boolean;
};

export type GetNextNewItemResponseDto = {
  nextId: number;
  newCount: number;
  currentIndex: number;
};

export type GetItemResponseDto = Item & {
  parentName: string;
  inquiryName: string;
  image: string | null;
  matchingProduct:
    | (Pick<Product, "id"> & {
        images: string[];
      })
    | null;
};

export type GetNewItemsRequest = {
  page: number;
  pageSize: number;
  sortModel?: GridSortModel;
  search?: string;
  inqView: string[];
};

export type ItemServerCrudResponse = {
  data: ItemFlattened[];
  rowCount: number;
  parentName: string;
  inquiryName: string;
  inquiryStatistics: ItemInquiryStatistics;
  orderStatistics: ItemOrderStatistics;
};

export type GetNewItemsResponse = {
  data: (ItemFlattened & { inqView: string })[];
  rowCount: number;
};

export type AddItemRequestDto = Omit<ItemDto, "id">;

export type EditItemRequestDto = ItemDto;

export type ChangeItemInquiryStatusRequestDto = {
  id: number;
  status: ItemInquiryStatus;
};

export type ChangeItemOrderStatusRequestDto = {
  id: number;
  status: ItemOrderStatus;
};

export type MatchingProduct = Pick<
  Product,
  | "id"
  | "name"
  | "retailPrice"
  | "supplierPrice"
  | "retailCurrency"
  | "commodityCode"
  | "replacementCommodityCode"
  | "color"
> & {
  supplierProductCode: SupplierProductCode;
  images: string[];
};

export type GetMatchingProductsListResponseDto = {
  A: MatchingProduct[];
  B: MatchingProduct[];
};

export type GetProductsToAssignRequestDto = {
  itemId: number;
  search: string;
  suppliersIds: string[];
};

export type GetProductsToAssignResponseDto = Array<
  Pick<
    Product,
    | "id"
    | "name"
    | "supplierPrice"
    | "supplierCurrency"
    | "retailPrice"
    | "retailCurrency"
    | "color"
  > & {
    supplierProductCode: SupplierProductCode;
    images: string[];
    commodityCodeRequiresApproval: boolean;
  }
>;

export type AssignProductRequestDto = {
  itemId: number;
  productId: number;
  productColor: ProductColors | "";
  productQuantity: number;
  match: MatchOption;
};

export type UnassignProductRequestDto = {
  id: number;
};

export type AssignItemsPurchaseOrderRequestDto = {
  itemsIds: number[];
  purchaseOrderId: number;
};

export type AssignItemsMainOrderRequestDto = {
  itemsIds: number[];
  mainOrderId: number;
};

export type DeleteItemRequestDto = {
  id: number;
};

export type SubmitRequestDto = {
  itemsIds: number[];
};
