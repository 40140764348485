import React from "react";
import { MatchingProduct as MatchingProductProp } from "../../../../slices/items/types";
import { Result } from "../Result";
import { MatchOption } from "../../../../types";

type MatchingProductProps = {
  product: MatchingProductProp;
  match: MatchOption;
};

export const MatchingProduct = ({ product, match }: MatchingProductProps) => {
  return (
    <Result
      data={{
        id: product.id,
        name: product.name,
        supplierProductCode: product.supplierProductCode,
        images: product.images,
        supplierPrice: product.supplierPrice,
        retailPrice: product.retailPrice,
        retailCurrency: product.retailCurrency,
        color: product.color,
        commodityCodeRequiresApproval:
          product.commodityCode?.requireApproval &&
          !product.replacementCommodityCode,
      }}
      match={match}
    />
  );
};
