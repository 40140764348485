import React, { useCallback, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetCountriesQuery } from "../../slices/countries/api";
import { PaperPageSelect } from "../layout/PaperPage";
import { useTranslation } from "react-i18next";

type SelectCountriesProps = {
  id: string;
  label: string;
  onChange?: (countryId: number | null) => void;
  value?: number | null;
  error?: boolean;
  helperText?: string;
};

export const AutocompleteCountry = ({
  id,
  label,
  onChange,
  value,
  error = false,
  helperText,
}: SelectCountriesProps) => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useGetCountriesQuery();

  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((country) => country.active).map(({ id }) => id);
  }, [data]);

  const changeHandler = useCallback(
    (e, value: number | null) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Autocomplete
      id={`select-country-${id}`}
      PaperComponent={PaperPageSelect}
      options={options}
      noOptionsText={t("emptySelect", { ns: "common" })}
      value={value ?? null}
      onChange={changeHandler}
      loading={isLoading}
      loadingText={t("loading", { ns: "common" })}
      getOptionLabel={(option) =>
        data.find(({ id }) => id === option)?.name ?? ""
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            error={error}
            helperText={helperText}
          />
        );
      }}
    />
  );
};
